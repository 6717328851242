@dashboard-section-spacing: 20px;
@import 'Eula.less';

.dashboard {
  background: none !important;
  display: flex;
  // flex-wrap: wrap;
  overflow: auto;
  position: relative;
  align-content: flex-start;
  flex-direction: column;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f4f4+0,efefef+46,e2e2e2+100 */
  background: #f4f4f4; /* Old browsers */
  background: -moz-linear-gradient(top, #f4f4f4 0%, #efefef 46%, #e2e2e2 100%);
  background: -webkit-linear-gradient(top, #f4f4f4 0%,#efefef 46%,#e2e2e2 100%);
  background: linear-gradient(to bottom, #f4f4f4 0%,#efefef 46%,#e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#e2e2e2',GradientType=0 );
  .dashboard-main {
    display: flex;
    flex-direction: row;
    margin-top: @dashboard-section-spacing;
    flex: 1;
    align-self: stretch;
    padding: 0 20px 2rem;
    overflow: visible;
  }

  .dashboard-main-aside {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 25%;      
    .module {
      flex: 1;
      margin-right: 0;
    }
  }
  
  .dashboard-main-feature {
    position: relative;
    padding-left: 30px;
    margin-top: 10px;
    flex-grow: 3;
    min-height: 450px;
  }

  .dashboard-modules {
    padding: @dashboard-section-spacing 0;
    // margin-bottom: @dashboard-section-spacing;
    text-align: left;
    white-space: nowrap;
    align-self: stretch;
    // z-index: 2;
  }
  .hidden-element, .hidden-sibling{
    height: 0;
    width: 0;
    z-index: -10;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  #unhide-sibling + .hidden-sibling{
    height: auto;
    width: auto;
    z-index: 100;
    overflow: inherit;
    position: relative;
    opacity: 1;
    pointer-events:all;
  }
  .respond-to-dashboard-single-column({
    flex-wrap: nowrap;
    .dashboard-main {
      flex-grow: 1;
    }
    .dashboard-main-aside {
      width: auto;
      flex: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .module {
        max-width: 320px;
        .Loader {
          .module-loader(150px);
          position: static;
          padding-top: 0;
          img {
            position: static;
            margin-top: 0;
            margin-left: 0;
          }
        }  
      }
    }
  });



// //TODO review once we implement !IE policy
//   .app:not(.bad-ie) & {
//     .dashboard-main {
//       position: relative;
//       align-items: center;
//       display: flex;
//       align-items: stretch;
//     }
//   }
//
//   .mobile & {
//     height: 100vh;
//   }

  // @media screen and (max-width: 896px) {
  //   .app:not(.mobile) & {
  //     height: 100vh;
  //   }
  // }

  // stacking order
  .app-header {
    z-index: 2;
  }

  .dashboard-modules {
    z-index: 3;  
  }

  .dashboard-main {
    z-index: 1;
    transform: translate3d(0,0,0);  
  }

}


@media screen and (max-width: 896px) and (min-aspect-ratio: ~"1/1") {
  .dashboard {
    .app-header {
      display: none;
    }
    .dashboard-main {
      padding-top: 0.5em;
      margin-top: 0px;
      margin-bottom: 0px;
      .historical-chart-inner {
        margin-top: -10px;
      }
    }

    .dashboard-main-feature {
      flex: 1;
      min-height: initial;
    }

    .menu {
      display:none;
    }

  }
  body {
    overflow: hidden;
  }
}