@tooltip-background: rgba(255, 255, 255, 0.9);
.tooltip {
  position: absolute;
  min-height: initial !important;
  top: 0;
  left: 0;
  background: @tooltip-background;
  border: none;
  border-radius: 0.5em;
  padding: 0 1em;
  pointer-events: none;
  // border: 1px solid @color-gray-dark;
  box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.10);
  font-size: 0.85em;

  .tooltip-arrow {
    position: absolute;
    width: 0;
  	height: 0;
  }
  &.top .tooltip-arrow {
    top: 100%;
    left: 50%;
    margin-left: -10px;
  	border-left: 10px solid transparent;
  	border-right: 10px solid transparent;
  	border-top: 10px solid @tooltip-background;
  }
  &.bottom .tooltip-arrow {
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
  	border-left: 10px solid transparent;
  	border-right: 10px solid transparent;
  	border-bottom: 10px solid @tooltip-background;
  }
  &.left .tooltip-arrow {
    left: 100%;
    top: 50%;
    margin-top: -10px;
  	border-top: 10px solid transparent;
  	border-bottom: 10px solid transparent;
  	border-left: 10px solid @tooltip-background;
  }
  &.right .tooltip-arrow {
    right: 100%;
    top: 50%;
    margin-top: -10px;
  	border-top: 10px solid transparent;
  	border-bottom: 10px solid transparent;
  	border-right: 10px solid @tooltip-background;
  }

  @arrow-border-width: 14.5px;

  &.border {
    border: 1px solid #666;
    .tooltip-arrow {
      &:before,
      &:after {
        content: '';
        position: absolute;
        border-bottom: 1px solid #666;
        height: 0;
        width: @arrow-border-width;
      }
    }
    &.top {
      .tooltip-arrow {
        transform: translate(0,5px);
        &:before {
          transform-origin: 0 0 0;
          left: -10px;
          top: -10px;
          transform: rotate(45deg);
        }
        &:after {
          transform-origin: 0 0 0;
          top: 0;
          right: -(@arrow-border-width - 0.5px);
          transform: rotate(-45deg);
        }
      }
    }
    &.right {
      .tooltip-arrow {
        transform: translate(0,5px);
        &:before {
          transform-origin: 0 0 0;
          left: 0;
          top: 0;
          transform: rotate(45deg);
        }
        &:after {
          transform-origin: 0 0 0;
          top: 0;
          right: -(@arrow-border-width - 0.5px);
          transform: rotate(-45deg);
        }
      }
    }
    &.bottom {
      .tooltip-arrow {
        transform: translate(0,5px);
        &:before {
          transform-origin: 0 0 0;
          left: -10px;
          top: 10px;
          transform: rotate(-45deg);
        }
        &:after {
          transform-origin: 0 0 0;
          top: 0;
          right: -(@arrow-border-width + 0.5px);
          transform: rotate(45deg);
        }
      }
    }
    &.left {
      .tooltip-arrow {
        transform: translate(0,5px);
        &:before {
          transform-origin: 0 0 0;
          left: -9.5px;
          top: -10px;
          transform: rotate(45deg);
        }
        &:after {
          transform-origin: 0 0 0;
          top: 10px;
          left: -10px;//(@arrow-border-width + 0.5px);
          transform: rotate(-45deg);
        }
      }
    }

  }

}
