.radial-dial {
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: visible;
  display: block;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 896px) {
    max-width: 700px;
  }

  .radial-background {
    fill: fade(@color-gray-light, 40%);
  }

  .inner-circle,
  .background-circle {
    fill: darken(@color-gray-dark, 15%);
  }

  .intervals {
    line {
      stroke-width: 1;
      stroke: @color-gray-light;
    }
  }

  .levels {
    circle {
      stroke: @color-gray-light;
      fill: rgba(0,0,0,0);
      stroke-width: 1;

      &.level-marker-0 {
        fill: darken(@color-gray-dark, 15%);
      }

      &:last-child {
        stroke-width: 3px;
        stroke: @color-white;
      }
    }
  }

  .ticks {
    path {
      stroke-width: 1px;
    }
  }

  .average {
    fill: rgba(0,0,0,0);
    stroke-width: 2;
    stroke: @color-white;
  }

}
