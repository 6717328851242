.main-menu {
  // height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .profile-link {
    height: @header-height;
    line-height: @header-height;
    padding-right: 1rem;
    font-size: 1.25em;
    .icon('gear');
  }

  & > ul {
    background-color: @color-blue;
    padding-bottom: 50px;
    position: relative;
    flex: 100%;
  }

  li {
    border-top: 1px solid @color-white;

    &:first-child {
      border: none;
    }

    .location-button {
      position: absolute;
      right: 0;
      .rotate-arrow {
        transition: transform 0.3s;
        &.active-menu {
          transform: rotate(0deg);
        }
        &.inactive-menu {
          transform: rotate(180deg);
        }
      }
    }

    &.download-data > a {
      display: flex;
      align-items: center;
      .button {
        width: auto;
        margin: 0 0 0 10px;
        .fa {
          line-height: 35px;
          margin: 0;
        }
      }
    }

  }

  .sub-list {
    background-color: @color-blue-light;
    margin-left: 0;
    border-top: 1px solid @color-white;

    li a,
    li .fake-link {
      padding-left: 2rem;
    }
  }

  .version {
    padding: 10px;
    text-align: right;
    color: @color-white;
    font-size: 80%;

    .App.browser & {
      display: none;
    }
  }

  .config-modal {
    .modal-box {
      max-width: 300px;
      text-align: center;
      top: 50%;
      left: 50%;
      bottom: auto;
      right: auto;
      border-radius: 5px;

      p {
        margin: 10px 0 30px;
      }
    }
  }
  .locationHolder {
    margin:0; 
    padding: 0;
    border: 0;
    overflow: hidden;
    transition: 0.5s;
    &.opened {
      max-height: 1000px;
    }
    &.options-1 {
      min-height: 57px;
    }
    &.options-2 {
      min-height: 114px;
    }
    &.options-3 {
      min-height: 171px;
    }
    &.options-4 {
      min-height: 228px;
    }
    &.options-5 {
      min-height: 285px;
    }
    &.options-6 {
      min-height: 342px;
    }
    &.options-7 {
      min-height: 399px;
    }
    &.options-8 {
      min-height: 456px;
    }
    &.closed {
      min-height: 0px;
      max-height: 0px;
    }
  }
  .notificationsMenuToggle {
    text-transform: capitalize;
    color: #ffffff;
    display:inline-block;
    &>span {
      width: 200px;
      display: inline-block;
    }
    &>.react-toggle{
      position: relative;
      top: 6px;
    }
  }
  .white {
    color: #ffffff; 
  }
}
