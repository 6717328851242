.live-usage-page {
  display: flex;
  flex-direction: column;
  background: @color-white;

  .unit-selection {
    left: auto;
    right: 5px;
    ul {
      right: 0;
      left: auto;
      transform: translateY(-10px);
    }
  }

  main {
    flex: 1;
    position: relative;
    flex-direction: column;
  }

  .chart-title-area {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: flex-end;
    .dropdown {
      text-align: left;

      .selected-label {
        padding: 5px;
        margin: 0 10px 0 0;
        border-radius: 2px;
        padding: 5px 0 20px 20px;
        border: 0;
        background: none;
      }

      ul {
        margin-left: -15px;
      }
    }
  }

  .chart-wrap {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
  }

  .live-usage-chart {
    position: absolute;
    .axis.y {
      .domain {
        visibility: hidden;
      }
    }
  }

  svg {
    flex: 1;
    height: auto;
  }
}
