input[type='radio'] {
  width: 1rem;
  height: 1rem;
  margin: 0;
  display: initial;
}

.curb-radio {
  display: flex;
  align-items: center;
  & > input {
    order: 2;
  }
  & > label {
    order: 1;
    flex-grow: 1;
  }
}

.curb-radio-group {
  & > label {
    display: block;
    padding-bottom: 1rem;
    font-size: 0.75rem;
  }

  &.radio-block {
    .options-wrapper {
      display: flex;
      .curb-radio,
      .curb-radio:first-child {
        display: flex;
        padding: 0;
        width: 0;
        flex: 1;
        flex-basis: 1;
        align-items: stretch;

        label {
          text-align: center;
          padding: 1rem;
          background: none;
          font-weight: bold;
          font-size: 1.1rem;
          color: @color-blue-dark;;
          border: 1px solid @color-blue-dark;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .label-content {
            opacity: 0.75;
          }
        }

        & + .curb-radio {
          label {
            border-left: none;
            border-radius: none;
          }
        }
        &:first-child {
          label {
            border-radius: 5px 0 0 5px;
          }
        }
        &:last-child {
          label {
            border-radius: 0 5px 5px 0;
          }
        }
      }
      input[type=radio] {
        visibility: hidden;
        width: 0;
        height: 0;
        &:checked  {
          & + label {
            background: @color-blue-dark;
            color: @color-white;
            .label-content {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
