html,
body {
  font-size: 100%;
}

body,
input,
select,
button,
textarea {
  font-family: @font-sans-serif;
  font-size: 16px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong,
b {
  font-weight: 700;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 1;
}

h1 {
  font-size: 2.4em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.2em;
}

p.small {
  font-size: 0.9rem;
}

main {

  p,
  li {
    margin-top: 1em;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0.6em;
    line-height: 1;
  }

  h1,
  h2,
  h3,
  p {
    &:first-child {
      margin-top: 0;
    }
  }

}
