//TODO styles need to be trimmed, just changed component name for spacing
@import 'circuits-list.less';
@import 'breakdownTooltip.less';
@import 'historical-charts.less';
@import 'totalsTooltip.less';
@import 'kwhrChart.less';

.historical-chart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .historical-options {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .time-range-selection {
      order: 2;
    }
  }

  .historical-navigation {
    display: flex;
    justify-content: space-between;
    font-size: 3rem;
    position: absolute;
    left: 2.1rem;
    right: -0.6rem;
    bottom: -1.8rem;
    z-index: 2;
    a {
      display: block;
      position: absolute;
      bottom: 0;
      padding: 0.5rem;
      opacity: 0.8;
      cursor: pointer;
      transition: opacity 0.1s ease-in-out;

      &:not([disabled]):hover {
        opacity: 1;
      }

      &[disabled] {
        color: @color-gray;
        cursor: default;
      }


      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }

    }
  }

  .data-plan-modal p {
    font-size: 120%;
    line-height: 140%;
    text-align: center;
  }

  .loading-overlay {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  .time-range-selection {
    text-align: center;
    z-index: 2;
    padding: 0.5em 0;

    .title,
    .tab {
      margin: 0 10px;
      padding: 5px 0;
    }

    .title {
      display: inline-block;
      vertical-align: top;
    }

    .tab {
      white-space: nowrap;
      display: inline-block;
      vertical-align: top;
      text-decoration: underline;
      text-underline-position: under;

      &.active {
        font-weight: 600;
        text-decoration: none;
      }
    }

    .month,
    .year,
    .all {
      background-color: rgba(255,255,255,.25);
      padding: 8px;
      border-radius: 3px;
      border-bottom: 0;

      strong {
        cursor: pointer;
      }

      span {
        border-bottom: 1px solid;
        display: block;
        margin-bottom: 4px;
      }

      i {
        padding: 5px 3px 0 3px;

        &.fa-dollar {
          padding-left: 1px;
          padding-right: 1px;
        }
      }
    }

    .year {
      border-radius: 3px 0 0 3px;

      i {
        opacity: 0;
      }
    }

    .all {
      margin-left: -10px;
      border-radius: 0 3px 3px 0;
      position: relative;

      .icons {
        border: 0;
        position: relative;
        left: -30px;
        margin-bottom: 0;
      }
    }
  }

  .format-selection {
    position: absolute;
    left: 8px;
    top: 50%;
    margin-top: 0px;
    z-index: 2;
    margin-top: 110px - 75px;

    .selected-label {
      font-size: 80%;

      .full-label {
        display: none;
      }
    }
  }

  .energy-type-selection {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 480px;
    margin-left: auto;
    margin-right: auto;
    .title {
      display: none;
    }

    .tab {
      padding: 5px 15px;
      border: 1px solid @color-blue-dark;
      border-right: 0;
      flex: 1;
      width: 0;

      &:first-of-type {
        border-radius: 3px 0 0 3px;
      }

      &:last-of-type {
        border-radius: 0 3px 3px 0;
        border-right: 1px solid @color-blue-dark;
      }

      &.active {
        background: @color-blue-dark;
        color: @color-white;
      }
    }
  }

  .respond-to-max-width (
    896px,
    {
      padding-left: 0;
      margin-top: 0;
      padding-bottom: 20px;

      .dropdown.format-selection {
        top: 20px;
        left: 20px;
        margin-top: 0;
        .selected-label {
          border-radius: 10px;
          padding: 10px;
        }
        .dropdown-list {
          left: 50px;
          top: 0;
          bottom: auto;
        }
      }

      .time-range-selection {
        font-size: 0.9em;
        .tab {
          margin: 0 5px;
        }
      }

      .historical-navigation a {
        bottom: 1.5rem;
        &.prev {
          left: -2.5rem;
        }
      }

    }
  );

  .respond-to-max-height(
    768px,
    {
      padding-top: 10px;
      min-height: initial;
    }
  );

}
