@import 'live-usage.less';
@import 'radial-dial.less';
@import 'unit-selection.less';

.radial-chart {
  text-align: center;
  position: relative;

  .radial-background {
    background: hsla(0,5%,96%,.4);
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 20px 0 0 0;
  }

  .radial-dial-chart-container {
    display: flex;
  }

  .radial-dial-wrap {
    position: relative;
    flex: 100%;
  }

  .live-usage-link-radial {
    display: block;
    position: relative;
  }

  .more-info {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .paren {
    font-size: 13px;
    padding-bottom: 15px;
  }

  .respond-to-dashboard-single-column({
    align-self: center;
    width: 75%;
    height: auto;
    min-width: initial;

    .radial-dial-wrap {
      flex: auto;
    }

  });

  @media screen and (min-aspect-ratio: ~"1/1") and (max-width: @dashboard-single-column-width) {
    height: 75%;
    width: auto;
    align-self: flex-start;
  }

}
