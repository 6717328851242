svg {
  g,
  rect {
    pointer-events: all;
  }
  .tick line {
    fill: none;
    stroke: #e5e5e5;
    shape-rendering: crispEdges;
  }
  .y.axis .tick:first-child line {
    display: none;
  }
  text {
    font-size: 12px;
    font-family: @font-sans-serif;
  }
}
