@checkbox-dimensions: 18px;

.curb-form {
  padding: .5em;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  justify-content: space-between;
  color: @color-blue-dark;

  label,
  .label {
    font-weight: normal;
    font-size: 0.75rem;
  }

  input,
  textarea,
  select {
    display: block;
    appearance: none;
    margin: 0.3rem 0;
    color: inherit;
    font-size: 1.15rem;
    width: 100%;
    border-width: 0 0 1px;
    border-style: solid;
    border-radius: 0;
    border-color: inherit;
    background: none;
    &[disabled] {
      opacity: 0.5;
      cursor: auto !important;
    }
  }

  input,
  select {
    height: 2.25rem;
    line-height: 2.25rem;
    padding: 0;
    .placeholder({
      color: rgba(7, 7, 78, 0.5);
    });
  }

  input[type="radio"] {
    width: 1rem;
    height: 1rem;
  }

  .curb-form-element,
  section {
    padding-top: 1rem;
    &:first-child {
      padding-top: 0;
    }
  }

  .compact {
    .curb-form-element {
      padding-top: 1px;
    }
  }

  // radio and checkbox shared layout
  .curb-checkbox,
  .curb-radio {
    position: relative;
    display: flex;
    align-items: center;
    label {
      cursor: pointer;
    }
    & > label {
      flex-grow: 1;
    }
  }

  .curb-group {
    padding-top: 1rem;
    .curb-group-item {
      padding-top: 1rem;
      &:first-child {
        padding-top: 0;
      }
    }
  }

  .curb-group-multi {
    .button {
      &.remove i {
        .icon('trash');
      }
      &.add i {
        .icon('plus');
      }
    }
    & > .curb-group-item {
      padding: 1em 0;
      .button-wrapper {
        &.right {
          text-align: right;
        }
      }

      // & + .curb-group-item {
      //   border-top: 1px solid @color-gray-dark;
      // }
    }
  }

  .error {
    border-color: red;
    .error {
      color: red;
    }
  }

  .phone-input.error {
    input {
      border-color: red;
    }
    .error {
      color: red;
    }
  }

  .button,
  button {
    display: inline-block !important;
    width: auto !important;
  }

  input[type='submit'],
  button[type='submit'] {
    .button();
    display: block !important;
    width: 100% !important;
    max-width: 300px;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    color: @color-white !important;
    background-color: @color-blue-light;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 45px;
    &.red {
      background: @color-red;
    }
    &.cta {
      border-radius: 5px;
      text-align: center;
    }
  }

  .textarea {
    min-height: 65px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    padding-top: 10px;
    [contenteditable=true] {
      border-bottom: 1px solid #fff;
      width: 100%;
      line-height: 1.3;
      padding-bottom: 6px;
    }
    &.error {
      [contenteditable=true] {
        border-bottom-color: @color-red;
        & + .error {
          padding-bottom: 0;
        }
      }
    }
  }

  &.busy:not(.autosave) {
    opacity: 0.5;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

}

@import "select.less";
@import "checkbox.less";
@import "radio.less";

.menu .curb-form,
.curb-form.dark {
  color: #fff;
  input,
  select,
  textarea {
    .placeholder({
      color: rgba(255,255,255,0.5);
    });
  }
}
