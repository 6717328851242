.Modal {
  .modal-box {
    position: fixed;
    z-index: 100001;
    background: @color-white;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 600px;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    background-clip: padding;
    overflow: hidden;

    header {
      text-align: center;
      padding: 10px;
      position: relative;
      border-bottom: 1px solid @color-gray;
      span {
        min-height: 20px;
        color: @color-gray-dark;
      }
      button {
        background: transparent;
        position: absolute;
        top: 0;
        padding: 0;
        right: 20px;
        .has-icon( 'times');
      }
    }

    footer {
      a {
        text-decoration: none;
      }
      button {
        background: transparent;
        color: @color-blue-light;
        border-radius: 0;
        border-top: 1px solid @color-gray;
        margin-bottom: 0;
        width: 50%;
      }

      button:not(:first-child) {
        border-left: 1px solid @color-gray;
      }
    }

    .modal-body {
      padding: 20px;
      white-space: pre-line;
      max-height:80vh;
      overflow:auto;
    }

    .modal-content {
      margin-top: 20px;
    }
  }

  &.large {
    .modal-box {
      left: 25%;
      transform: translate(-15%, -50%);
      width: 72.5%;
    }
  }
  // @media screen and (max-width: 600px) {
  //   .modal-box {
  //     border-radius: 0;
  //     overflow: visible;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //     margin: 0;
  //     width: auto;
  //   }
  // }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    z-index: 10000;
  }
}
