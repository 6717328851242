.unit-selection {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 3;
  font-size: .8em;
  text-align: left;

  .long {
    display: inline;
  }

  .short {
    display: none;
  }

  .selected-label {
    background: none;
    border: none;
    padding: 5%;

    i {
      padding-left: 3px;
    }
  }

  ul {
    margin-left: 0;
    martin-top: 0;
  }

}
