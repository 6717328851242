#dashboard-wrapper {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}

a {
  .link-styling(@color-blue);
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}
