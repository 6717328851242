.smartthings-page {

  .smartthings-page-wrapper {
    background-size: cover;
    height: auto;
    margin: 0 auto;
    text-align: center;

    .smartthings-disclaimer {
      font-style: italic;
      margin: 15px;
      padding:15px;
      background:#6464a3;
      color:#FFFFFF;
      border: 1px #777 solid;
      border-radius: 5px;
      font-weight: bold;
    }

    p {
      text-align: left;
      padding:10px;
    }

    h3 {
      margin:20px;
      padding:10px;
      background:#b5d886;
      border: 1px #777 solid;
      border-radius: 5px;
    }

    pre{
      text-align: left;
      font-size: 80%;
      background: white;
      max-height: 300px;
      max-width: inherit;
      overflow: scroll;
      padding: 10px;
      border: 1px #777 solid;
      margin: 10px;
    }

    .smartthings-auth-creds-input {
      text-align: left;
      width:100%;
      input{
        width:300px;
      }
      .smartthings-auth-creds-input-label {
        text-align: right;
        display: inline-block;
        width: 110px;
      }
    }

    .smartthings-auth-creds-authorize {
      float: right;
    }

    .smartthings-auth-creds-left {
      padding:10px;
    }

  }

}
