.error-boundary {
  padding: 1em;
  margin: 1rem auto;
  border: 1px solid @color-red;
  background: rgba(red(@color-red), green(@color-red), blue(@color-red), 0.2);

  .error-title {
    font-weight: bold;
    color: @color-red;
  }
  .error-content {
    margin: 1em 0;
    font-family: monospace;
  }

  .stack {
    font-size: 0.9em;
    margin: 1rem 0 0;
    white-space: pre-wrap;
  }

  .support-email {
    font-weight: bold;
    text-decoration: underline;
    color: @color-blue;
  }

  &.top-level-error {
    font-size: 1.5rem;
    .error-title {
      font-size: 2.5rem;
    }
    .error-content {
      padding: 1em;
      background: @color-red;
    }
  }
}