.tabs {
  .tab {
    cursor: pointer;

    &[disabled] {
      cursor: default;
      opacity: 0.8;
      color: #999;
      border-top-color: #999 !important;
      border-bottom-color: #999 !important;
      & + &,
      &:first-of-type {
        border-left-color: #999 !important;
      }
      &:last-child, {
        border-right-color: #999 !important;
      }
    }
  }
}
