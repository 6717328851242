html {

  body {
    background: #f4f4f4;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    #app-wrapper {
      height: 100%;
    }

    & {
      background: #f4f4f4;
      background: -moz-linear-gradient(top, #F7F7F7 0%, #efefef 46%, #cccccc 100%);
      background: -webkit-linear-gradient(top, #F7F7F7 0%,#efefef 46%,#cccccc 100%);
      background: linear-gradient(to bottom, #F7F7F7 0%,#efefef 46%,#cccccc 100%);
    }

    &.curb {
      &,
      .app {
        background-image: linear-gradient(to bottom, #ccee99 0%,#bbdd88 45%,#aacc88 100%);
        background-size: cover;
      }
    }

    &.elevation {
      .app-header .header-logo {
        height: 32px;
        // padding-bottom: 5px;
        // .respond-to-tablet({
        //   height: 35px;
        // })
      }
    }

    &.hidden {
      .app {
        visibility: hidden;
      }
    }

  }

}

.app {
  max-height: initial;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.menu-open {
    overflow: hidden;
  }
  & > div {
    z-index: 1;
    min-height: 100%;
    flex-grow: 1;
    &.tooltip {
      z-index: 2;
    }
  }
}

.basic-page {
  main {
    max-width: 1024px;
    margin: 0 auto;
    padding: 2em;
    background: rgba(255,255,255,0.7);
  }
  .alerts-list {
    position: static;
  }
}

/* PHONEGAP OVERRIDES */
// html.native  {
//
//   body.ios .app {
//
//     .notchless-ios(@screen-height, @statusbar-height: 20px){
//       &,
//       .joyride {
//         height: @screen-height - @statusbar-height;
//       }
//       .joyride {
//         position: absolute;
//         top: -@statusbar-height;
//       }
//     }
//
//     /*
//       we set the height of the dashboard on a per-device basis for iOS because the InnAppBrowser plugin
//       does not account for the height of the status bar when viewport-fit=cover is used (which is needed for iphone x)
//       Also, it does not update the device-width and device-height when changing orientation, so you have to deliberately check for landscape
//     */
//
//     .dashboard {
//       /* iphone 5 */
//       @media only screen and (device-width : 320px) and (device-height : 568px) {
//         .notchless-ios(568px);
//       }
//       @media only screen and (device-width : 320px) and (device-height : 568px) and (orientation : landscape) {
//         .notchless-ios(320px);
//       }
//
//       /* iphone 6/7/8 */
//       @media only screen and (device-width : 375px) and (device-height : 667px) {
//         .notchless-ios(667px);
//       }
//       @media only screen and (device-width : 375px) and (device-height : 667px) and (orientation : landscape) {
//         .notchless-ios(375px);
//       }
//
//       /* iphone 6/7/8 plus */
//       @media only screen and (device-width : 414px) and (device-height : 736px) {
//         .notchless-ios(736px);
//       }
//       @media only screen and (device-width : 414px) and (device-height : 736px) and (orientation : landscape) {
//         .notchless-ios(414px);
//       }
//
//       /* ipad */
//       @media only screen and (device-width : 768px) and (device-height : 1024px) {
//         .notchless-ios(1024px);
//       }
//       @media only screen and (device-width : 768px) and (device-height : 1024px) and (orientation : landscape) {
//         .notchless-ios(768px);
//       }
//
//       /* ipad pro */
//       @media only screen and (device-width : 834px) and (device-height : 1112px) {
//         .notchless-ios(1112px);
//       }
//       @media only screen and (device-width : 834px) and (device-height : 1112px) and (orientation : landscape) {
//         .notchless-ios(834px);
//       }
//
//       /* ipad pro larger? */
//       @media only screen and (device-width : 1024px) and (device-height : 1366px) {
//         .notchless-ios(1366px);
//       }
//       @media only screen and (device-width : 1024px) and (device-height : 1366px) and (orientation : landscape) {
//         .notchless-ios(1024px);
//       }
//     }
//
//     /* iphone x w/ "the notch" */
//     /*
//     The InnAppBrowser does not use the proper safe-area variables when changing between portait and landscape
//     So we basically hard code a bunch of dimensions here
//     */
//     @iphone-x-height: 812px;
//     @iphone-x-top-safe-area: 44px;
//     @iphone-x-bottom-safe-area: 30px;
//     @inner-height: @iphone-x-height - @iphone-x-top-safe-area - @iphone-x-bottom-safe-area;
//     @media only screen and (device-width : 375px) and (device-height : @iphone-x-height) {
//       min-height: @inner-height;
//       padding: @iphone-x-top-safe-area 0 @iphone-x-bottom-safe-area;
//       position: relative;
//       .dashboard {
//         height: @inner-height;
//         padding-bottom: @iphone-x-bottom-safe-area;
//         overflow: visible;
//         .joyride {
//           height: @inner-height;
//           position: absolute;
//           top: -@iphone-x-top-safe-area;
//         }
//       }
//       .menu {
//         bottom: -@iphone-x-bottom-safe-area;
//         .main-menu {
//           height: auto;
//         }
//       }
//       .full-screen-page {
//         min-height: 768px;
//       }
//     }
//     @media only screen and (device-width : 375px) and (device-height : 812px) and (orientation : landscape) {
//       padding-top: 44px;
//       padding-left: 30px;
//       padding-right: 30px;
//       min-height: 375px;
//       .dashboard,
//       .full-screen-page {
//         margin-bottom: -30px;
//         padding-bottom: 30px;
//       }
//       .dashboard {
//         height: 331px;
//       }
//       .full-screen-page {
//         min-height: 331px;
//         width: 812px;
//         margin-left: -30px;
//         margin-right: -30px;
//         padding-left: 30px;
//         padding-right: 30px;
//       }
//     }
//
//   }
//
//   // .full-screen-page {
//   //   position: absolute;
//   //   bottom: 0;
//   //   min-height: initial !important;
//   // }
//
// }
