.energy-provider-form {
  .input-radio {
    display: inline-block;
    vertical-align: middle;
    input {
      width: 1em;
      height: 1em;
    }
    & + label {
      padding-left: 0.5rem;
    }
  }
}
