.energy-provider-menu {
  .title {
    text-align: center;
    padding: 0.5em 0;
  }

  display: flex;
  flex-direction: column;

  .billing-type {
    display: flex;
    text-align: center;
    margin: 0.5em 0 .75em;

    li {
      flex: 100%;
    }

    label {
      display: block;
      font-size: 18px;

      input {
        display: inline-block;
      }
      span {
        display: block;
      }
    }
  }

  .UtilitySelection {
    margin-bottom: 1em;
  }

  .sub-button {
    background-color:@color-blue-dark;
  }
}
