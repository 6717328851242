.app a.more-info {
  &:before {
    content: '(';
    margin-right: 3px;
    text-decoration: none;
  }
  &:after {
    content: ')';
    margin-left: 3px;
    text-decoration: none;
  }

  .link-styling();
  font-size: 13px;
  text-decoration: none;

  span {
    text-decoration: underline;
  }
  &:not(.inline){
    line-height: 40px;
    display: block;
  }
  &:hover {
    color: @color-blue-dark;
  }
}
