.eula-content{
  p{
    margin-bottom:10px;
    text-align: center;
  }
  ul {
    margin:15px;
  }
  li {
    margin-bottom:5px;
    list-style:inside;
  }
  h3{
    margin-bottom:10px;
  }
  text-align: center;
  line-height: 1.6;
}
