@large-loader-width: 40%;

.Loader {
  position: relative;
  text-align: center;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    opacity: 0.5;
    margin-left: -60px;
    margin-top: -60px;
    width: 100%;
    width: 120px;
    height: 120px;
    height: auto;
    animation: spin 4s linear infinite;
  }
  &.fill {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    position: absolute;
    img {
      position: absolute;
      display: inline;
    }
  }
  &.white {
    background-color: #fff;
  }

  &.large {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: static;
      margin: 0;
      width: auto;
      height: @large-loader-width;
      .respond-to-portrait({
        width: @large-loader-width;
        height: auto;
      })
    }
  }

  &.static {
    img {
      animation: fadein ease-in-out 1s;
    }
  }


}
