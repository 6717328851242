@powerband-line-color: #07074e;

.power-band {

  .margin-top {
    margin-top: 50px;
  }

  .chart-container {
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .mean {
    display: block !important;
    text-align: center;
    text-shadow: white 0 0 5px;
    justify-content: center;
    padding-top: 10px;

    span {
      display: block;
    }

    .watts{
      font-weight: 600;
      font-size: 14px;
    }

    .title{
      font-size: 14px;
    }
  }

  .min-max {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: row;
    margin-top: 31%;
    padding: 0px 5px 0px 5px;
    text-shadow: white 0 0 5px;

    .max {
      display: block;
      text-align: right;

      span {
        display: block;
      }

      .watts{
        font-weight: 600;
        font-size: 14px;
      }

      .title {
        font-size: 14px;
      }
    }

    .min {
      display: block;
      text-align: left;

      span {
        display: block;
      }

      .monthly {
        font-size: 10px;
        display: inline-block;
        padding-left: 2px;
        font-weight: normal;
      }

      .watts, .dollars {
        font-weight: 600;
        font-size: 14px;
      }

      .title {
        font-size: 14px;
      }
    }
  }

  .violin-labels {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
    & > div {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  .powerband-chart {
    .powerband-bounds {
      stroke: @powerband-line-color;
    }
    .band {
      shape-rendering: geometricPrecision;
      pointer-events: none;
      fill: url(#area-gradient);
      stroke-width: 0px;
    }
    .interaction-line {
      pointer-events: none;
    }
  }

}

.power-band-tooltip {
  color: #448;
  padding: 0 10px;
  pointer-events: none;

  .power-band-header {
    .historical-tooltip-date-time();
  }

  .label {
    padding-top: 5px;
    padding-bottom: 3px;
    vertical-align: middle;
    margin-left: 5px;
    font-weight: 600;
  }

  .body {
    padding-bottom: 3px;
    padding-top: 3px;
    vertical-align: middle;
    margin-left: 5px;
  }

  .price {
    vertical-align: middle;
    margin-left: 5px;
    padding-top: 3px;
    padding-bottom: 10px;

    .time {
      font-size: 10px;
    }
  }
}
