.messages-list-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: @color-gray-light;
}

.messages-list-menu,
.messages-detail-menu {
  white-space: normal;
  .Loader.large img {
    width: 40%;
    height: auto;
  }
}

.messages-list-menu header.Header {
  max-width: 550px;
  left: auto;
}
