.curb-checkbox {
  position: relative;
  display: flex;
  label {
    cursor: pointer;
    font-size: 1.15em;
  }
  & > label {
    flex-grow: 1;
  }
  .input-checkbox {
    position: relative;
  }
  input[type=checkbox] {
    visibility: hidden;
    width: @checkbox-dimensions;
    height: @checkbox-dimensions;
    & + label {
      width: @checkbox-dimensions;
      height: @checkbox-dimensions;
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      top: 0;
      border-width: 1px;
      border-style: solid;
      border-color: inherit;
      border-radius: 3px;
      padding: 0.5rem 0;
      &:after {
        content: '';
        opacity: 0;
        width: 12px;
        height: 7px;
        position: absolute;
        top: 3px;
        left: 3px;
        border-width: 2px;
        border-style: solid;
        border-top: none;
        border-right: none;
        background: transparent;
        transform: rotate(-45deg);
      }
    }
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.curb-checkbox-group {
  .curb-group-item {
    label {
      font-weight: normal;
    }
  }
}
