.messages-actions-bar {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 1px;
  border-top: 1px solid #999;
  overflow: hidden;
  background: @color-gray-light;
  a {
    color: #333;
    padding: 0.75em;
    font-size: 1.2em;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &,
    &:hover,
    &:active {
      text-decoration: none;
    }    
  }
  &:not(.disabled) a {
    &:hover,
    &:active {
      color: @color-blue;
      transform: scale(1.1);
    }
  }

  .action-flag {
    .icon('flag');
  }
  .action-share {
    text-align: center;
    .icon('share');
  }
  .action-delete {
    .icon('trash-o');
  }
  &.disabled a {
    opacity: 0.5;
  }
  .Modal {
    position: absolute;
  }
}

.messages-delete-modal {
  .modal-box {
    footer {
      .button-cancel {
        color: #1480f9;
      }
      .button-confirm {
        font-weight: 700;
        color: red;
      }
    }
  }
}
