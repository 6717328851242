.full-screen-page {
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  .full-screen()
}

.full-screen {
  .page();
}

main {
  z-index: 1;
  & > .alerts-list {
    position: absolute;
    width: 100%;
    z-index: 6;
  }
}

.col {
  display: flex;
  justify-content: space-between;
  &.col-2 {
    & > * {
      padding: 0;
      width: 48%;
    }
  }
}
