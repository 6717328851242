#location-detail-form {
  &.busy {
    opacity: 1;
    &:after {
      display: none;
    }
  }
}

.location-form {
  .energy-enhancements {
    .title,
    .inputs {
      flex: 1;
      padding: 0.5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .inputs .checkbox-group {
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }

  }
}
