.icon(@icon) {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  @var: 'fa-var-@{icon}';

  &:before {
    content: @@var;
  }
}

.has-icon(@icon) {
  i {
    .icon(@icon);
  }
}

.button(@color: @color-blue-light, @font-color: @color-white) {
  display: block;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
  color: @font-color;
  background-color: @color;
  text-align: center;
  outline: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  border-radius: 3px;
  margin: 10px auto;
  // max-width:80%;

  &:active {
    background-color: darken(@color, 5%);
  }
}

.full-screen(){
  min-height: 100vh;
}

.page(){
  & > header,
  & > .header,
  & > .Header {
    height: 50px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    & > .button,
    & > div[role=button],
    & > a {
      &:first-child {
        position: absolute;
        left: 0;
      }
    }
    .right {
      position: absolute;
      right: 0;
    }
    & + div,
    & + main,
    & + .main {
      display: flex;
      overflow: auto;
      flex-direction: column;
    }
  }
}

.link-styling(@color: @color-blue-dark) {
  text-decoration: underline;
  color: @color;
}

.placeholder(@rules) {

    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
    &.placeholder {
        @rules();
    }
}

//delete these once we create
//our own tooltip component that already
//has all of this built in
.historical-tooltips() {
  color: @color-blue;
  min-width: @tooltip-min-width;
  max-width: @tooltip-max-width;
}

.historical-tooltip-key() {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid @color-gray-dark;
}

.historical-tooltip-date-time() {
  padding: 10px 0 5px;
  border-bottom: @color-blue solid 1px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.historical-tooltip-title() {
  vertical-align: middle;
  margin-left: 5px;
  font-weight: 600;
}

.shrink() {
  .selected-label {
    transform: scale(.8);
    transform-origin: top left;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  .long {
    display: none;
  }
  .short {
    display: inline;
  }
}

.gray-gradient() {
  background: #DBDCDD;
  background: -moz-linear-gradient(to bottom, #FFFFFF 0% ,#DBDCDD 100%);
  background: -webkit-linear-gradient(to bottom, #FFFFFF 0% ,#DBDCDD 100%);
  background: linear-gradient(to bottom, #FFFFFF 0% ,#DBDCDD 100%);
}

/* RESPONSIVE MIXINS */
// TODO make more of these for different responsive breakpoints
// and use them instead of ad-hoc media queries
@iphone-6-plus-width: 414px;
@iphone-6-width: 375px;
@iphone-4-width: 320px;
@ipad-portrait-width: 768px;
@dashboard-single-column-width: 896px;

.respond-to-max-width(@maxWidth; @rules){
  @media only screen and (max-width: @maxWidth) {
      @rules();
  };
}

.respond-to-min-width(@maxWidth; @rules){
  @media only screen and (min-width: @maxWidth) {
      @rules();
  };
}

.respond-to-max-height(@maxWidth; @rules){
  @media only screen and (max-height: @maxWidth) {
      @rules();
  };
}

.respond-to-min-height(@maxWidth; @rules){
  @media only screen and (min-height: @maxWidth) {
      @rules();
  };
}

.respond-to-tablet(@rules){
  .respond-to-max-width(@ipad-portrait-width, @rules);
}

.respond-to-mobile-large(@rules){
  .respond-to-max-width(@iphone-6-plus-width, @rules);
}

.respond-to-mobile-small(@rules){
  .respond-to-max-width(@iphone-4-width, @rules);
}

.respond-to-dashboard-single-column(@rules){
  .respond-to-max-width(@dashboard-single-column-width, @rules);
}

.respond-to-landscape(@rules){
  @media only screen and (min-aspect-ratio: ~"1/1") {
    @rules();
  }
}

.respond-to-portrait(@rules){
  @media only screen and (max-aspect-ratio: ~"1/1") {
    @rules();
  }
}
