.totals-tooltip {
  color: @color-blue;
  min-width: @tooltip-min-width;
  max-width: @tooltip-max-width;
  padding: 0 10px 10px;

  &.empty {
    width: 0;
  }

  .date-time {
    padding: 10px 0 5px;
    border-bottom: @color-blue solid 1px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  .datum {
    margin: 10px 10px 5px;

    .title {
      vertical-align: middle;
      margin-left: 5px;
      font-weight: 600;
    }

    .key {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 4px;
      vertical-align: middle;
      border: 1px solid @color-gray-dark;
    }
  }

  .values {
    margin-left: 25px;
    > * {
      margin: 5px 0;
    }
  }
}
