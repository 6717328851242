.collapse-group {
  &.open {
    & > .section-header {
      background: #fff;
      & > i {
        transform: rotate(90deg);
      }
    }
  }
}

.section-header {
  display: flex;
  cursor: pointer;
  padding: 0.8rem;
  padding-right: 0;
  background: rgba(255,255,255,0.5);
  border-bottom: 1px solid @color-gray-dark;
  h3 {
    flex-grow: 1;
  }
  i {
    transition: transform 0.1s ease-in-out;
    align-self: center;
    margin-right: 5px;
    .icon('chevron-right');
  }
}

.ReactCollapse--content {
  padding: 1rem;
  & > * {
    margin: 0;
  }
}
