button,
.button {
  &:not(.react-datepicker__navigation){
    display: inline-block;
    height: 45px;
    line-height: 45px;
    padding: 0 0.5em;
    color: white;
    background: rgba(68,68,136,0.9);
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  
    &.small {
      height: 1.8em;
      line-height: 1.8em;
      padding: 0 0.3em;
      i {
        font-size: 0.7em;
        vertical-align: middle;
        line-height: 1;
      }
    }
  
    &:hover,
    &:active {
      background: @color-blue;
    }
  
    &.red {
      background: @color-red;
    }
    &.large {
      height: 60px;
      line-height: 60px;
      min-width: 300px;
    }  
  }
}
