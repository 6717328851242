// https://fontawesome.com/v4.7.0/cheatsheet/

.icon-solar {
  .icon('sun-o');
}

.icon-battery {
  .icon('battery-three-quarters');
  transform: rotate(-90deg);
}

.icon-add {
  .icon('plus-circle');
}

.icon-delete {
  .icon('minus-circle');
}

.icon-delete-location {
  .icon('minus-circle');
}

.icon-caret-left {
  .icon('caret-left');
}

.icon-caret-right {
  .icon('caret-right');
}

.icon-arrow-right {
  .icon('chevron-right');
}

.icon-arrow-left {
  .icon('chevron-left');
}

.icon-trash {
  .icon('trash');
}

.icon-edit {
  .icon('pencil');
}

.icon-refresh {
  .icon('refresh');
}

.icon-status-success {
  .icon('check');
}

.icon-frown {
  .icon('frown-o');
}

// .icon-status-busy {
//   .icon('ellipsis-h');
//   .flicker();
// }

.icon-status-error {
  .icon('exclamation-circle');
}

.icon-status-error-triangle {
  .icon('exclamation-triangle');
}

.icon-status-placeholder {
  .icon('question-circle');
}
