.login, .forgot-password {

  width: 300px;
  margin: 0 auto;
  min-height: 400px;
  background: none !important;

  h2 {
    color: @color-blue-dark;
    margin: 0 auto;
  }

  p {
    margin: auto;
    text-align: center;
  }

  .logo img {
    margin: 0 auto;
    display: block;
    width: 300px;
    margin-top: 50px;
    margin-bottom: 20px;
    @media only screen and (max-height: 730px) {
      margin-top: 20px;
      width: 285px;
      height: auto;
    }

    @media only screen and (max-height: 650px) {
      width: 200px;
      height: 204px;
    }

    @media only screen and (max-height: 590px) {
      width: 150px;
      height: 153px;
    }

  }


  form {
    margin: 0 auto;
    margin-top: 15px;
  }

  .login-form, .forgot-password-form {

    label {
      color: @color-gray-dark;
      display: block;
    }

    input[type='password'],
    input[type='email']  {
      display: inline-block;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      color: black;
      width: 100%;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    button.sign-in-button {
      display: inline-block;
      height: 45px;
      line-height: 45px;
      color: white;
      background: @color-blue;
      width: 100%;
    }

    p {
      text-align: center;
      margin-top: 35px;
      @media only screen and (max-height: 730px) {
        margin-top:10px;
        padding: 5px;
      }
      .password-link {
        color: @color-gray-dark;
      }
    }

    .demo-link {
      color: @color-blue;
      .loader-inline {
        position: absolute;
        margin-top: -0.25em;
      }
    }

    a:hover {
      color: @color-white;
    }

  }

}
