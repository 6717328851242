.message-details-page {
  width: 100%;
  main {
    flex-grow: 1;
  }
}

.message-detail {
  .messages-actions-bar {
    border-top: 0;
    border-bottom: 1px solid #999;
  }
  .message-content {
    padding: 1em 0;
  }
  .message-header {
    .title {
      white-space: normal;
    }
  }
  .message-body {
    p,
    ul,
    ol,
    li {
      margin-top: 1em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.small-screen-width {
  .messages-actions-bar {
    border-top: 1px solid #999;
    border-bottom: none;
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    overflow: visible;
  }

  .message-details-page {
    padding-bottom: 3em;
  }

  &.touch-enabled {
    .toggle-edit-mode {
      display: none;
    }
  }

}
