@background: rgba(255,255,255,0.8);
.circuits-list {
  margin-right: 40px;
  position: relative;
  right: -1px;
  top: 1px;
  z-index: 2;
  .toggle-list {
    cursor: pointer;
    background: transparent;
    position: absolute;
    right: 0;
    bottom: -1.15em;
    padding: 0.5em;
    background: @background;
    border-radius: 0.5em 0.5em 0 0;
    .icon-caret {
      .has-icon('angle-down');
      margin-right: 10px;
    }
    .icon-list {
      .has-icon('list-ul');
    }
  }
  .circuits {
    display:none;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    position: absolute;
    right: 0;
    width: 80%;
    padding: 1em;
    border-radius: 0.5em 0 0.5em 0.5em;
    transform: translate(0, 1.1em);
    background: @background;
    max-height: 50vh;
    overflow: auto;
    .controls {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5em;
      span {
        font-size: 0.9em;
        margin: 0 1em;
        cursor: pointer;
        i {
          margin-right: 0.3em;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .select-all {
        .has-icon('check-square-o');
      }
      .deselect-all {
        .has-icon('square-o');
      }
    }
    .circuits-wrapper {
      display: flex;
      ul {
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 1;
        width: 0;
      }
      li {
        cursor: pointer;
        margin: 0;
        padding: 0.5em;
        display: flex;
        position: relative;
        overflow: hidden;
        &:hover {
          background: #fff;
          text-decoration: underline;
        }
        &.inactive {
          .tile {
            background-color: transparent !important;
          }
        }
      }
      .tile {
        display: inline-block;
        height: 1em;
        width: 1em;
        margin-right: 0.3em;
        border-radius: 0.2em;
        flex-shrink: 0;
        border-width: 1px;
        border-style: solid;
      }
      .label {
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &.open {
    .toggle-list {
      background: @background;
      transition: background ease-in-out 0.2s;
      .icon-caret {
        .has-icon('angle-up');
      }
    }
    .circuits {
      display: block;
      opacity: 1;
    }
  }

  // mobile uses a modal
  .circuits-modal {
    header {
      display: none;
    }
    .circuits {
      position: static;
      width: auto;
      padding: 0;
      margin-top: -1.5em;
    }
  }

  .respond-to-max-width(896px, {
    top: -9px;
  })

}

.mobile-landscape {
  .circuits-list .circuits {
    max-height: 65vh;
  }
}