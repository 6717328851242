.Header {
  background-color: @color-blue;
  height: @header-height;
  box-shadow: 0px -11px 70px 0px rgba(0,0,0,0.22);
  text-align: center;
  position: relative;

  a {
    text-decoration: none;
  }

  .page-title, .backLink {
    padding: 5px;
    line-height: @header-height - 10px;
    display: inline-block;
  }

  .page-title {
    color: @color-white;
    font-size: 18px;
  }

  .backLink {
    height: @header-height;
    padding-left: 1rem;
    color: @color-white;
    cursor: pointer;
    .icon {
      .icon('chevron-left');
    }
  }

}
