.stacked-area {
  .interaction-line {
    visibility: hidden;
    stroke: #eee;
    stroke-width: 1px;
    pointer-events: none;
    &.active {
      visibility: visible;
    }
  }
  .layer {
    cursor: pointer;
  }
}
