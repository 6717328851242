@import 'MessagesMenu.less';
@import 'ConfigurationsMenu.less';
@import 'EnergyProviderMenu.less';
@import 'MainMenu.less';
@import 'SupportMenu.less';

.menu {
  background-color: @color-blue;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  opacity: 0;
  transition: z-index 0ms linear 0ms, opacity 300ms linear 0ms;

  @media screen and (max-width: 500px) {
    transition: z-index 0ms linear 0ms, opacity 150ms linear 0ms;
  }

  &.configurations-section {
    .menu-container {
      overflow: hidden;
    }
  }

  .menu-container {
    max-width: 550px;
    width: 0%;
    position: absolute;
    right: 0;
    background-color: @color-blue;
    overflow: auto;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    // height: 100%;
    z-index: 2;
    transition: width 400ms linear 200ms;
    @media screen and (max-width: 500px) {
      transition: width 200ms linear 100ms;
    }
    & > div {
      .page();
    }
  }

  &.modal-open .menu-container {
    overflow: visible;
  }

  .menu-overlay {
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }

  .menu-content {
    padding: 1rem;
    white-space: normal;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .link-list {
      margin: 0 -1rem;
      li {
        border-top: 1px solid @color-white;
        position: relative;
        &:first-child {
          border-top: none;
        }
        .anchor {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .fa {
          margin-right: 0;
        }
        .value {
          line-height: 1;
        }
        label {
          font-size: 0.8em;
          margin: 0;
          padding: 0 0 0.5em;
          line-height: 1;
          display: block;
          cursor: pointer;
        }
      }
    }
  }

    &.closed {
      z-index: -2;
      transition: z-index 0ms linear 600ms, opacity 300ms linear 300ms;
      margin-top: 0;

      @media screen and (max-width: 500px) {
        transition: z-index 0ms linear 300ms, opacity 150ms linear 150ms;
      }

      .menu-container {
        width: 0%;
        transition: width 400ms linear 0ms;

        @media screen and (max-width: 500px) {
          transition: width 200ms linear 0ms;
        }
      }
    }

    &.open {
      opacity: 1;
      z-index: 20000;
      margin-top: 0;

      .menu-container {
        width: 90%;
      }
    }

  .Header {
    background-color: @color-blue-dark;
    height: @header-height;
    position: relative;
  }

  .backButton {
    display: flex;
    height: 100%;
    width: 40px;
    padding-left: 5px;

    .fa {
      color: @color-white;
      align-self: center;
      font-size: 16px;
    }
  }

  .logout {
    cursor: pointer;
  }

  .return-link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .full-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .menu-content {
    form {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: stretch;
      justify-content: flex-start;
      .form-actions {
        button,
        input[type="submit"] {
          margin-bottom: 0;
        }
      }
    }
  }

  header,
  .menu-section-dark {
    &,
    & a {
      color: @color-white;
    }
  }

  .sub-list a,
  .fake-link,
  .link-list a {
    color: @color-white;
    display: block;
    line-height: 2.5rem;
    padding: .25rem 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background .1s ease-in-out;
    &.active {
      cursor: auto;
    }

    &.support {
      cursor: default;
    }

    &:not(.active):hover {
      background: rgba(7,7,78,.25);
    }

    .fa {
      display: inline-block;
      vertical-align: center;
      line-height: 3rem;
      margin-right: 1rem;
    }
  }

  @import 'menu-form.less';

}
