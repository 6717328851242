header {
  z-index: 2;
}

.app-header {
  margin: 0 20px;
  padding: 10px 5px 5px;
  height: 60px;
  border-bottom: 1px solid @color-blue-dark;
  position: relative;
  z-index: 2;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  .provider-location-group {
    display: flex;
    flex-grow: 10;
    align-content: center;
  }

  .header-logo {
    height: 30px;
    max-width: 80%;
    vertical-align: middle;
    align-self: center;
    margin-right: 30px;
    border-right: 1px solid @color-blue-dark;
    padding-right: 30px;

  }

  .header-location {
    position: relative;
    display: flex;
    color: @color-blue;
    line-height: 40px;
    align-items: center;
    overflow: hidden;
    .location-select-wrapper {
      position: relative;
      max-width: 100%;
      display: flex;
    }
    .single-location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multi-location-selector {
      display: inline-block;
    }
    .icon-down-arrow {
      .has-icon('angle-down');
      display: inline-block;
      padding-left: 5px;
    }
    select {
      height: auto;
      line-height: normal;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: transparent;
      border: none;
      color: @color-blue;
      line-height: 30px;
      vertical-align: middle;
      margin-bottom: 0;
      padding-right: 2px;
    }
  }

  .location-search-autocomplete {
    padding-left: 0.5em;
    width: 240px;
    i {
      display: none;
    }
    .location-search-content {
      display: block;
    }
    .input-wrap {
      position: relative;
      input.autocomplete[type="text"] {
        color: #333;
        border-width: 0 0 1px;
        background: transparent;
        border-width: 0 0 1px;
        border-color: @color-gray-dark;
        width: 100%;
        font-size: 18px;
        margin: 0 0 4px;
        display: block;
        z-index: 1;
        height: 36px;
        line-height: 35px;
        padding-left: 1.6em;
        .placeholder({
          color: #333;
          opacity: 0.5;
        });
      }
      .input-icon {
        .icon('search');
        z-index: 2;
        position: absolute;
        left: 5px;
        top: 0.3em;
        color: @color-gray-dark;
        opacity: 0.5;
        font-size: 1.2em;
      }
    }
    .locations-autocomplete {
      background: #fff;
      max-height: 280px;
      overflow: auto;
      border-color: @color-blue-dark;
      border-width: 0 1px 1px;
      border-style: solid;
      border-radius: 0 0 10px 10px;
      &:empty {
        display: none;
      }

      li {
        padding: 1em;
        border-top: 1px solid @color-gray-dark;
        cursor: pointer;
        &.loading {
          text-align: center;
          .loader-inline {
            height: 1.5em;
            width: 1.5em;
          }
        }
        &:hover,
        &:focus {
          &:not(.loading){
            background: @color-blue-light;
            color: #fff;
          }
        }
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .powered-by-curb {
    font-size:0.65em;
    margin-top: 5px;
    text-align:right;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    img {
      height:13px;
      vertical-align: middle;
      }
    }
  .header-menu-link {
    padding: 10px;
    margin-right: -10px;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    .bars {
      .icon('bars');
      font-size: 1.5em;
      color: @color-blue-dark;
    }
  }

  .respond-to-tablet({
    @mobile-logo-height: 20px;
    .header-logo {
      height: @mobile-logo-height;
      margin-right: @mobile-logo-height;
      padding-right: @mobile-logo-height;
    }

    .header-location {
      flex-grow: 1;
      align-items: center;
      select {
        padding: 0 5px 0 0;
      }
    }

    // mobile fleet search
    .provider-location-group {
      overflow: hidden;
      z-index: 2;
      i {
        display: inline;
        color: @color-blue-dark;
        &.icon-search {
          .icon('search');
        }
        &.icon-close {
          .icon('close');
        }
      }
      .location-search-autocomplete {
        width: auto;
        i {
          font-size: 1.4em;
          padding: 10px;
          height: 1.4rem;
          transform: translateY(-4px);
        }

        .location-search-content {
          overflow: hidden;
          width: 240px;
          position: absolute;
          top: 100%;
          right: 0;
          transform: translate(0, 1px);
          background: #fff;
          border: 1px solid @color-blue-dark;
          border-top: none;
          border-radius: 0 0 5px 5px;
          &.hidden {
            display: none;
          }
          input[type="text"] {
            margin: 0;
            padding-right: 5px;
            border: none;
          }
          ul {
            border-color: @color-gray-dark;
            border-width: 1px 0 0;
          }
        }

      }
    }


  });

}
