.historical-kwhr {
  .column {
    rect {
      cursor: pointer;
      fill-opacity: 0.7;
      transition: all .2s ease-in-out;
      &.active,
      &[data-id=__NET__] {
        fill-opacity: 1;
      }
    }
  }
}
