.device-control {
  padding-bottom: 0;

  &:not(.loading) .module-inner {
    justify-content: flex-start;
  }

  &.setup .module-inner {
    justify-content: center;
    a {
      text-align: center;
      display: block;
    }
  }


  .module-inner {
    overflow: auto;
    padding-bottom: 10px;  
  }

  .devices-state {
    padding-bottom: 0.5rem;

    .device-row {
      cursor: pointer;

      &:hover {
        .label {
          text-decoration: underline;
        }
      }

      td {
        padding: 6px 0;
      }

      &.loading {
        cursor: auto;
        opacity: 0.5;
      }
    }

    .label {
      font-size: 0.9rem;
    }
    .status {
      text-align: center;
      vertical-align: top;
      line-height: 1;
    }

    .auth-link {
      margin-top: 1rem;
      display: block;
    }
  }

  .smartthings-link {
    display: block;
    text-align: center;
    font-size: inherit;
    margin-top: 46px;
  }  

  .device {
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    padding: .1em 0 0 .2em;
    margin-top: 0.8em;
    &:first-child {
      margin-top: 0;
    }
  }

  .toggle {
    display: block;
    width: 1.2em;
    height: 1.2em;
    background: @color-gray;
    border-radius: 50%;
    margin-right: 4px;
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;

    .Loader {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin: 0;
    }
  }

  .on .toggle {
    background-color: @color-green;
  }

  a:active.on .toggle {
    background-color: lighten(@color-green, 13%);
  }

  a:active.off .toggle {
    background-color: lighten(@color-gray, 13%);
  }

  .pending {
    opacity: .6;

    .toggle {
      background: none;
    }
  }

  &.unauthorized,
  &.failed {
    text-align: center;
    padding-top: 1em;
  }

}
