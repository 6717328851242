html:not(.ios-native){
  .app {
    // notched device considerations
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
    padding-left: constant(safe-area-inset-left); /* iOS 11.0 */
    padding-left: env(safe-area-inset-left); /* iOS 11.2 */
    padding-right: constant(safe-area-inset-right); /* iOS 11.0 */
    padding-right: env(safe-area-inset-right); /* iOS 11.2 */
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  }
  .full-screen-page {
    top: constant(safe-area-inset-top); /* iOS 11.0 */
    top: env(safe-area-inset-top); /* iOS 11.2 */
    left: constant(safe-area-inset-left); /* iOS 11.0 */
    left: env(safe-area-inset-left); /* iOS 11.2 */
    right: constant(safe-area-inset-right); /* iOS 11.0 */
    right: env(safe-area-inset-right); /* iOS 11.2 */
  }


  .fixed-top {
    top: constant(safe-area-inset-top); /* iOS 11.0 */
    top: env(safe-area-inset-top); /* iOS 11.2 */
  }
  .full-screen-fixed {
    bottom: -constant(safe-area-inset-bottom); /* iOS 11.0 */
    bottom: -env(safe-area-inset-bottom); /* iOS 11.2 */
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  }
  .menu .menu-container .link-list {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  }

}

// html.ios-notchless .full-screen-page {
//   padding-bottom: 20px;
//   .joyride-overlay {
//     height: auto !important;
//     transform: translateY(-20px);
//   }
// }

// html.ios-native {
//   .app {
//     padding-bottom: 20px;
//   }
// }
//
/* notched ios */
html.iphone-x-xs,
html.iphone-xr-xs-max {
  .app {
    height: 100%;
    min-height: initial;
  }

  body.overscroll {
    .app {
      height: 100%;
      overflow: auto;
    }
  }

  .full-screen-page {
    min-height: 100%;
    overflow: visible;
  }

  &.portrait {
    .fixed-top {
      top: 44px;
    }
    .full-screen-fixed {
      bottom: -34px;
      padding-bottom: 34px;
    }
    .menu .menu-container .link-list {
      padding-bottom: 34px;
    }
  }

  .messages-list-page {
    height: 100%;
    overflow: auto;
  }

  .joyride-overlay {
    height: auto !important;
    transform: translateY(-44px);
  }

  .small-screen-width .messages-actions-bar {
    padding-bottom: 34px;
  }
}

// set the actions bar up high so that it doesn't overlay safe area
html .small-screen-width .messages-actions-bar {
  bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}
