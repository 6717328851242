.cost-breakdown {
  text-align: center;
  color: @color-blue-dark;
  align-self: center;
  display: flex;
  flex-direction: column;

  &.utility {
    // cursor: pointer;
  }

  .module-inner {
    justify-content: center;
  }

  .utility-message {
    margin-top: 0.8em;
  }

  .tariff {
    padding-top: 0.5em;
    font-size: 0.8em;
    p {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .value {
    font-size: 2em;
  }

  .link-wrap {
    margin-top: 3px;
    font-size: 13px;
    color: @color-blue-dark;
  }

}
