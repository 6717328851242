.historical-chart-inner {
  flex-grow: 3;
  height: auto;
  width: 100%;
  position: relative;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;

  .graph-overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    &.loading img {
      opacity: 1;
    }
    &.error {
      display: flex;
      padding: 0 3em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  & > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    path.layer {
      fill-opacity: 0.7;
      transition: all ease-in-out 0.2s;
      &.active {
        fill-opacity: 1;
      }
    }

    .interaction-line {
      visibility: hidden;
      stroke: #eee;
      stroke-width: 1px;
      pointer-events: none;
      &.active {
        visibility: visible;
      }
    }

    .axis {
      text {
        font-family: @font-sans-serif;
        font-size: 12px;
      }

      &.y .tick.zero {
        &:not(first-of-type),
        &:not(last-of-type) {
          text {
            display: none;
          }
        }
      }
    }

  }

  .historical-circuits-chart {
    .tick:first-child,
    .tick:nth-last-child(2) {
      text {
        font-weight: 700;
      }
    }

    .tick.zero line {
      opacity: 1;
      stroke: @color-blue-dark;
    }

  }

  .historical-net-chart {
    .tick.zero line {
      stroke: #000;
    }
  }

  .line-chart,
  .stacked-area {
    .axis.x {
      .tick:first-of-type text {
        text-anchor: start;
        font-weight: bold;
      }

      .tick:last-of-type text {
        text-anchor: end;
        font-weight: bold;
      }
    }
  }

  &.loading {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    opacity: .6;
  }

}
