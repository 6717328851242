@font-face {
    font-family: 'Lato';
    src: url('@{staticPath}/fonts/lato/lato-bold-webfont.eot');
    src: url('@{staticPath}/fonts/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{staticPath}/fonts/lato/lato-bold-webfont.woff2') format('woff2'),
         url('@{staticPath}/fonts/lato/lato-bold-webfont.woff') format('woff'),
         url('@{staticPath}/fonts/lato/lato-bold-webfont.ttf') format('truetype'),
         url('@{staticPath}/fonts/lato/lato-bold-webfont.svg#lato') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('@{staticPath}/fonts/lato/lato-light-webfont.eot');
    src: url('@{staticPath}/fonts/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{staticPath}/fonts/lato/lato-light-webfont.woff2') format('woff2'),
         url('@{staticPath}/fonts/lato/lato-light-webfont.woff') format('woff'),
         url('@{staticPath}/fonts/lato/lato-light-webfont.ttf') format('truetype'),
         url('@{staticPath}/fonts/lato/lato-light-webfont.svg#lato') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('@{staticPath}/fonts/lato/lato-regular-webfont.eot');
    src: url('@{staticPath}/fonts/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{staticPath}/fonts/lato/lato-regular-webfont.woff2') format('woff2'),
         url('@{staticPath}/fonts/lato/lato-regular-webfont.woff') format('woff'),
         url('@{staticPath}/fonts/lato/lato-regular-webfont.ttf') format('truetype'),
         url('@{staticPath}/fonts/lato/lato-regular-webfont.svg#lato') format('svg');
    font-weight: normal;
    font-style: normal;
}
