.recent-use {
  display: flex;
  flex-direction: column;

  .recent-use-chart {
    margin: 0 12%;
    .arc {
      fill-opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        fill-opacity: 1;
        transform: scale(1.05);
      }
    }
  }

  &.error {
    .pie-chart {
      display: none;
    }
  }

}

.recent-use-tooltip {
  color: @color-blue;
  min-width: 140px;
  max-width: 240px;

  &.empty {
    width: 0;
  }

  .date-time {
    padding: 10px 0 5px;
    border-bottom: @color-blue solid 1px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  .highlight {
    padding: 10px;

    .title {
      vertical-align: middle;
      margin-left: 5px;
      font-weight: 600;
    }

    .key {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 4px;
      vertical-align: middle;
      border: 1px solid @color-gray-dark;
    }
  }

  .name {
    margin-bottom: 5px;
  }

  .values {
    margin-left: 25px;
    > * {
      margin: 5px 0;
    }

    span {
      font-size: 10px;
      display: inline-block;
      padding-left: 2px;
      font-weight: normal;
    }
  }

  .total {
    padding: 10px;

    .name {
      margin-left: 25px;
      font-weight: 600;
    }
  }

  .totals {
    font-style: italic;
  }

}

.dashboard-main-aside {
  .energy-source {
    .energy-source-chart {
      flex: auto;
    }
    &.loading {
      svg {
        display: none;
      }
    }
  }
}
