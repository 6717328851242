.breakdown-tooltip {
  color: @color-blue;
  min-width: @tooltip-min-width;
  max-width: @tooltip-max-width;
  .sub-label {
    font-size: 10px;
  }
  &.empty {
    width: 0;
  }

  .date-time {
    padding: 10px 0 5px;
    border-bottom: @color-blue solid 1px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .percentage {
    font-style:italic;
  }
  .highlight {
    padding: 10px;

    .title {
      vertical-align: middle;
      margin-left: 5px;
      font-weight: 600;
    }

    .key {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 4px;
      vertical-align: middle;
      border: 1px solid @color-gray-dark;
    }

    & + .total {
      border-top: @color-gray solid 1px;
    }

  }

  .name {
    margin-bottom: 5px;
  }

  .values {
    margin-left: 25px;
    > * {
      margin: 5px 0;
    }
  }

  .total {
    padding: 10px;

    .name {
      margin-left: 25px;
      font-weight: 600;
    }
  }

  .totals {
    font-style: italic;
  }

  .list {
    padding: 0.5em 0;
    margin: 0;
    & > li {
      display: flex;
      padding: 0.25em 0.5em;
      margin: 0 -0.5em;
      &:nth-child(even) {
        background: rgba(200,200,200,.7);
      }
    }
    &.credits {
      .header {
        background-color: @color-green;
      }
    }
    &.fixed {
      .header {
        color: @color-white;
        background-color: @color-blue;
      }
    }
    &.consumption {
      .header {
        background-color: @color-red;
        color: @color-white;
      }
    }
    .header {
      font-weight: bold;
    }
    .title {
      vertical-align: unset;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
      line-height: 1;
      padding-right: 10px;
    }
    .value {
      font-weight: bold;
    }
  }

}
