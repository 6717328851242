@import 'update-profile.less';
@import 'location-form.less';
@import 'energy-provider-form.less';
@import 'user-detail-form.less';
@import 'load-control.less';

form {
  .form-title {
    font-size: 1.1em;
    line-height: 1.2;
  }
  .form-section {
    padding: 2rem 0 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .form-actions {
    padding-top: 2em;
  }
}
