@loader-inset-factor: 0.3em;
.loader-inline {
  margin: 0 @loader-inset-factor;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-indent: -9999em;
  border-top: @loader-inset-factor solid rgba(255, 255, 255, 0.2);
  border-right: @loader-inset-factor solid rgba(255, 255, 255, 0.2);
  border-bottom: @loader-inset-factor solid rgba(255, 255, 255, 0.2);
  border-left: @loader-inset-factor solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  &.blue {
    border-top-color: rgba(68, 68, 136, 0.4);
    border-right-color: rgba(68, 68, 136, 0.4);
    border-bottom-color: rgba(68, 68, 136, 0.4);
    border-left-color: @color-blue;
  }

  &,
  &:after {
    border-radius: 50%;
    width: 1.5em + @loader-inset-factor;
    height: 1.5em + @loader-inset-factor;
  }  

  &.tiny {
    margin-top: -3px;
    margin-bottom: -3px;
    &,
    &:after {
      width: 1.1em + @loader-inset-factor;
      height: 1.1em + @loader-inset-factor;      
    }
  }

}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button .loader-inline {
  visibility: hidden;
  position: absolute;
  margin-top: 0.5em;
}

&.busy .loader-inline {
  visibility: visible;
}
