@message-overflow-right: 16px;
@message-width: calc(~'100% + '@message-overflow-right);

.messages-list-page {
  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 0.5em;
    .backLink {
      position: static;
      text-align: left;
      margin-left: 2px;
      .icon {
        padding-right: 5px;
      }
    }

    nav {
      align-self: center;
      text-align: right;
      .nav-icon {
        padding: 0.5em;
        margin-right: -0.5em;
        color: #fff;
        .icon('navicon');
      }
    }

    .backLink,
    nav {
      width: 18%;
      white-space: nowrap;
    }

  }
}

.message-count {
  text-align: center;
  margin-left: 5px;
  color: #fff;
  line-height: 1.5;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: red;
  font-family: @font-sans-serif;
  &.overflow {
    font-size: 0.8em;
    width: 1.9em;
    height: 1.9em;
    line-height: 1.9;
  }
}

.messages-list {
  a {
    text-decoration: none;
  }

  .messages-list-header {
    padding: 1em 0;
    & > .flex-container {
      display: flex;
      justify-content: space-between;
    }
    .toggle-edit-mode {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .messages-actions-bar {
    // don't display:none because the delete modal is inside
    height: 0;
    border-top: none;
    a {
      margin: -0.75em;
    }
  }

  .messages-loading-indicator {
    padding: 1rem;
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }

  .message {
    border-top: 1px solid #999;
    margin: 0;
    position: relative;
    overflow: hidden;

    .message-inner-wrapper.snap {
      transition: transform 0.2s ease-in-out;
    }

    .message-link {
      display: block;
      padding: 1em @message-overflow-right 1em 1.1em;
      color: #333;
      cursor: pointer;
      width: @message-width;
      &.highlight {
        background: #ddd;
      }
      &:before {
        content: '';
        line-height: 1;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8em;
        display: block;
        position: absolute;
        top: 1.2rem;
        left: 0;
      }

    }

    &.unread .message-link:before {
      content: @fa-var-circle;
      color: @color-green;
    }

    &:last-child {
      border-bottom: 1px solid #999;
    }
    .excerpt {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .slide-in {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      text-align: center;
      width: 80px;
      .icon {
        display: block;
        font-size: 40px;
      }
    }

    .message-flag {
      border-right: 1px solid #999;
      right: 100%;
      .icon {
        .icon('flag-o');
      }
    }

    .message-delete {
      border-left: 1px solid #999;
      left: 100%;
      margin-left: @message-overflow-right;
      .icon {
        .icon('trash-o');
      }
    }

  }

  /* edit mode */
  &.edit-mode {
    .messages-list-header {
      .count {
        display: none;
      }
    }

    .messages-actions-bar {
      height: auto;
    }

    .message {
      .message-link {
        &:hover {
          background: none;
        }
        &:before {
          color: #333;
          content: @fa-var-square-o;
          font-size: 1em;
          transform: translate(0, -2px);
        }
      }
      &.selected a:before {
        content: @fa-var-check-square-o;
      }
    }

  }
}

.message-header {
  display: flex;
  justify-content: space-between;
  padding: 0.1em 0 0.3em;
  .title {
    font-size: 1em;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    flex-shrink: 1;
    width: 0;
  }
  .date {
    white-space: nowrap;
    position: relative;
    text-align: right;
    padding-left: 1em;
  }
}

.small-screen-width .messages-list-page {
  .messages-actions-bar {
    height: 0;
    border-top: none;
    overflow: visible; /* so that modal can show up */
  }
}
