.dropdown {
  position: relative;
  font-weight: 600;
  font-size: 80%;

  @item-height: 25px;
  @button-height: 44px;
  @border-width: 1px;

  // TODO - this can't be necessary? manually positioning each list item?
  // .dropdown-loop(@counter) when (@counter > 0) {
  //   // .dropdown-loop((@counter - 1));
  //   &.selected-item-@{counter} {
  //     .dropdown-rules(@counter);
  //   }
  // }
  //
  // .dropdown-rules(@counter) {
  //   & when (@counter = 0) {
  //     ul {
  //       margin-top: 0;
  //     }
  //   }
  //   & when (@counter > 0) {
  //     @normal-margin-top: -@item-height * @counter + (@button-height/2 - @item-height/2);
  //     ul {
  //       margin-top: @normal-margin-top;
  //     }
  //
  //     &.last ul {
  //       margin-top: @normal-margin-top + (@button-height/2 - @item-height/2) - @border-width * 2;
  //     }
  //   }
  // }

  &:not(.static) {
    //here's where we call the loop
    // .dropdown-loop(10);
  }

  .selected-label {
    background: @color-gray-light;
    border: 1px solid @color-gray;
    padding: 15px 5px;
    border-radius: 10px 0 0 10px;
    display: inline-block;
    .has-icon('caret-down');
    cursor: pointer;
  }

  ul {
    background: @color-gray-light;
    border: 1px solid @color-gray;
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    top: auto;
    overflow: hidden;
    box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.40);
    text-align: left;

    li {
      padding: 5px;
      cursor: pointer;
      padding-left: 15px;
      white-space: nowrap;
      margin: 0;

      &[disabled] {
        cursor: default;
        opacity: .5;
      }

      & span {
        padding-left: 5px;
      }

      &.active {
        color: gray;
        background: @color-gray-light;
        border-radius: 5px;
        padding-left: 5px;
      }

      &:hover {
        background: @color-blue;
        color: #fff;
      }
    }

    .active i {
      .icon('check');
      display: inline;
      color: gray;
      position: relative;
    }
  }

  &.open ul {
    display: block;
  }

  &.down {
    ul {
      bottom: auto;
      top: 100%;
    }
  }

}
