.curb-select {
  .select-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    select {
      width: auto;
      margin-right: auto;
      flex-grow: 1;
      background: transparent;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      &[disabled] + i {
        opacity: 0.5;
      }
      // for windows
      option {
        color: #000;
      }
    }
    i {
      .icon('sort');
      position: absolute;
      align-self: center;
      right: 5px;
    }
    & + div {
      margin-top: 10px;
    }
  }
}
