.cost-breakdown-page {
  background: @color-white;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  main {
    flex-grow: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
  }

  .margin-top {
    margin-top: 40px;
  }

  .chart-container {
    flex: 1;
    height: auto;
    position: relative;
    display: flex;
  }

  .chart-header {
    text-align: center;
    position: relative;
    h1 {
      font-weight: 400;
      font-size: 1.1em;
      margin-bottom: 0;
    }
    .summary {
      margin: 0;
      font-size: 1em;
      display: none;
      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .item {
        margin: 0.2em 0 0;
        & + .item {
          margin-left: 1em;
        }
        &.production {
          color: #007c00;
        }
        &.consumption {
          color: #ed2024;
        }
        &.total {
          font-weight: 700;
        }
      }
    }
  }

}

.cost-breakdown-chart {

  position: absolute;

  .tick line {
    stroke: #c5c5c5;
    opacity: 1;
    shape-rendering: crispEdges;
  }

  .bar {
    rect {
      fill-opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        fill-opacity: 1;
      }
    }
  }
}

.cost-breakdown-tooltip {
  min-width: 100px;
  padding: 1em;
  background: #fff;
  .name {
    font-weight: 600;
    border-bottom: 1px solid @color-gray-dark;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  &.top .tooltip-arrow {
    border-top-color: #fff;
  }

  &.right .tooltip-arrow {
    border-right-color: #fff;
  }

  &.left .tooltip-arrow {
    border-left-color: #fff;
  }

  .title {
    .historical-tooltip-title();
  }

  .key {
    .historical-tooltip-key();
  }

  .value {
    margin-left: 20px;
  }
}

@media (max-width: 430px) {
  .values(0, 20, 32);
}

@media (max-width: 599px) {
  .values(0, 20, 40);
}

@media (min-width: 600px) and (max-width: 800px) {
  .values(0, 20, 60);
}

.values(@i, @minValue, @maxValue) when (@i < 60 - @minValue) {
  @combined: @minValue + @i;
  @fontSize: 12px;
  // .CostBreakdownPage.values-@{combined} .nvd3 text {
  //   font-size: @fontSize - @fontSize * min(@i / (@maxValue - @minValue), .8);
  // }
  .values(@i + 1, @minValue, @maxValue);
}
