html, body {
  border: 0;
  margin: 0;
  padding: 0;
}

div, span, object, iframe, img, table, caption, thead, tbody,
tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu,
nav, footer, header, section, summary, mark, audio, video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
ol, ul, li, fieldset, legend, label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, canvas, figure, figure img, figcaption, hgroup,
footer, header, nav, section, audio, video {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  caption, th, td {
    text-align: left;
    vertical-align: middle;
  }
}

a img {
  border: 0;
}

:focus {
  outline: 0;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: @color-white;
}

button,
input[type='submit'],
input[type='button'] {
  .button();
}

input[type='checkbox'] {
  margin: 0;
}

select,
button,
input {
  &:disabled {
    opacity: .5;
  }
}

select {
  display: block;
}

.clearfix {
  zoom:1;
  &:before, &:after{ content:""; display:table; }
  &:after{ clear: both; }
}
