.energy-source {
  margin-right: 15px;
  display: flex;
  align-items: stretch;

  .energy-source-chart {
    flex:1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text.value {
      font-weight: 700;
    }
    .baseline {
      stroke: #000;
    }

    &.three-col {
      .axis {
        text {
          font-size: 11px;
        }
      }
    }

  }

}

.dashboard-main-aside {
  .energy-source {
    .energy-source-chart {
      flex: auto;
    }
  }
}

