.live-usage {
  position: absolute;
  width: 35%;
  height: 35%;
  top: 50%;
  left: 50%;
  margin: -17.5% 0 0 -17.5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  color: @color-white;
  z-index: 2;

  .value {
    font-size: 2.2em;
    font-weight: bold;
    vertical-align: top;
  }

  .scale {
    font-size: 1.2em;
    vertical-align: top;
  }

  .mobile & {
    .value {
      font-size: 2.5em;
    }

    .scale {
      font-size: 1.5em;
    }

    @media screen and (max-width: 400px) {
      .value {
        font-size: 1.75em;
      }

      .scale {
        font-size: 1em;
      }
    }

    @media screen and (max-height: 600px) {
      .value {
        font-size: 1.75em;
      }

      .scale {
        font-size: 1em;
      }
    }
  }
}
