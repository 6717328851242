.messages-container {
  width: 100%;
  padding: 0 2em 34px;
  .empty-message {
    padding: 1em 0;
    text-align: center;
  }
}

@import 'list.less';
@import 'detail.less';
@import 'actions-bar.less';
