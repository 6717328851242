//this styling is all provisional, need UX

.select-location {

  width: 300px;
  margin: 0 auto;
  min-height: 400px;
  margin-top: 50px;
  margin-bottom: 20px;

  h1 {
    color: @color-blue-dark;
    text-align: center;
    padding-bottom: 30px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: @color-blue-light;
    color: @color-white;
    border-radius: 4px;
    opacity: .85;
  }



}
