.SupportMenu {
  display: flex;
  flex-direction: column;
  background-color: @color-gray-light;

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
  }

  ul {
    margin: -1em -1em 0 -1em;

    li {
      padding: 1.5em;
      border-bottom: 1px solid @color-white;
    }

    a {
      color: @color-blue-dark;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .contact {
    display: block;
    width: 100%;
    background-color: @color-blue;
    padding: 1em;
    text-align: center;
  }
}
