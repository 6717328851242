@gutters: 15px;

.modules {
  // spacer pseudo element to ensure first module lines up with historical Y axis
  // shrinks down automatically when there is not enough screen width
  &:before {
    content: '';
    flex-basis: ~"calc(25% + 77px)";
    flex-shrink: 1;
  }

  // fixed width pseudo element to ensure right side of modules
  // aligns with right edge of historical
  &:after {
    content: '';
    flex-grow: 0;
    flex-shrink: 0;
    width: 49px;
  }

  // min-height: 210px;
  text-align: left;
  white-space: nowrap;
  padding-left: @gutters;
  padding-right: 0;
  vertical-align: top;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;

  .module {
    height: 200px;
    width: 200px;  
    margin: 0 .4em;
    flex-grow: 0;     /* do not grow   - initial value: 0 */
    flex-shrink: 0;   /* do not shrink - initial value: 1 */
    flex-basis: 200px; /* width/height  - initial value: auto */
    align-self: flex-start;    
  }
}

.module-loader(@size:60px) {
  width: @size;
  height: @size;
  position: relative;
  padding-top: @size/2;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  img {
    width: 100%;
    height: 100%;
    margin-top: -@size/2;
    margin-left: -@size/2;
  }
}

.module {
  background-color: rgba(255,255,255,.5);
  display: flex;
  flex-direction: column;  
  border-radius: 5px;
  padding: .5em;  
  color: @color-blue-dark;
  white-space: normal;
  vertical-align: top;
  .module-title {
    width: 100%;
    padding-bottom: .25em;
    color: @color-blue-dark;
    border-bottom: 1px solid;
    padding-left: 2px;
    text-align: left;
    i {
      padding-right: 5px;
    }
  }

  .module-inner {
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    justify-content: center;
    flex: 100%;
  }

  .error:not(.module) {
    text-align: center;
  }  

  .Loader {
    .module-loader();
  }

  .mobile & {
    margin: 0;
  }

  &.loading {
    svg {
      display: none;
    }
  }  

  a,
  a.more-info {
    color: @color-blue;
    cursor: pointer;
    &.on,
    &:active,
    &:hover {
      color: @color-blue-dark;
    }
  }  
}



  // .app:not(.mobile) & {
  //   @media screen and (min-width: 1100px) {
  //     text-align: left;
  //     width: auto;
  //     padding-right: 0;
  //   }
  //
  //   @media (max-width: 1500px) and (min-width: 1100px) {
  //     margin-left: 0;
  //     margin-right: 3%;
  //     justify-content: flex-end;
  //   }
  // }

body.small-screen-width {
  .modules {
    &:after {
      flex-basis: @gutters;
    }
  }
}

@import 'cost-breakdown.less';
@import 'energy-saver.less';
@import 'device-control.less';
@import 'energy-source.less';
@import 'power-band.less';
@import 'recent-use.less';
