.energy-saver {

  .module-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }

  .module-inner {
    flex-grow: 1;
    text-align: center;
    overflow: auto;
    p {
      padding: 0 0.5rem;
      & + p {
        margin: 0.2rem 0 0;
      }
    }
    .circuit-label {
      font-weight: bold;
    }
    & > *:first-child {
      margin-top: auto;
    }
    & > *:last-child {
      margin-bottom: auto;
    }
  }

  .Loader {
    padding-top: 0;
    margin-top: 0;
  }

  .module-title i {
    display: inline-block;
    transform-origin: 50% 50%;
    padding: 0;
    margin-right: 7px;
  }

  &.active {
    .module-title i {
      animation: spin 2s linear infinite;
    }
    .module-inner {
      justify-content: flex-start;
    }
  }

  &.snoozed {
    .module-inner {
      p {
        font-size: 0.9rem;
      }
    }
  }

  button.action {
    font-size: 14px;
    white-space: nowrap;
  }

}

.snooze-modal.Modal {
  .modal-box {
    footer .button-confirm {
      color: @color-red;
    }
  }
}
