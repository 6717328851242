@import 'font-awesome.less';
// @import (inline) '../../../node_modules/nvd3/build/nv.d3.css';
// @import (inline) '../../../../node_modules/react-joyride/lib/react-joyride-compiled.css';

.nvtooltip {
  border-radius: 0;
  padding: 0px;
  font-family: @font-sans-serif;
  background: none;
  border: 0;

  span {
    display: inline;
    margin: 0;
  }
}

/* added this because nvtooltip is mutated in chartComponent
and needed it not to be for powerband tooltip -si
*/
.d3tooltip {
  border-radius: 0;
  padding: 0px;
  font-family: @font-sans-serif;
  background: none;
  border: 0;
  z-index: 10000;
  font-size: 13px;
  text-align: left;
  pointer-events: none;
  white-space: nowrap;

  span {
    display: inline;
    margin: 0;
  }
}

.tooltip:not(.empty) {
  box-shadow: -1px 5px 10px 5px rgba(0,0,0,.1);
}

.react-joyride__tooltip {
  button[role=button] {
    height: auto;
    margin: 0;
    &:hover {
      opacity: 0.8;
    }
  }
  p + p {
    margin-top: 1rem;
  }
}
