.alerts-list {
  margin: 0;
  padding: 0.5rem;
}

.alert {
  list-style: none;
  position: relative;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ddd;
  border: 1px solid @color-gray-dark;
  & + .alert {
    margin-top: 20px;
  }
  &.error {
    background: @color-red;
    border-color: #94001e;
    color: #fff;
  }
  &.warning {
    background-color: @color-orange;
    border-color: #9a5802;
  }
  &.success {
    color: #fff;
    background-color: @color-blue-light;
    border-color: @color-blue-dark;
  }
  &.status {
    color: #fff;
    background-color: @color-blue-dark;
    border-color: transparent;
  }

  .title {
    font-size: 1.1em;
    font-weight: bold;
  }

  .close {
    i {
      .icon('close');
      color: #fff;
      margin-right: -0.1em;
    }
    width: 1.5em;
    height: 1.5em;
    line-height: 1.4;
    text-align: center;
    background: @color-gray-dark;
    border: 1px solid #333;
    border-radius: 1.5em;
    text-align: center;
    display: block;
    position: absolute;
    top: -0.35em;
    right: -0.35em;
    padding: 0;
  }

}
