.configurations-menu {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-x: hidden;

  &.loading .header {
    display: block;
  }

  .header {
    display: none;
  }

  .iframe-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }

  .configurations-iframe {
    width: 100%;
    height: 100%;
    // Since display: block; breaks safari scrolling we have to use
    // the old inline-block fix. #sigh
    margin-bottom: -4px;
  }

}
