// @sans-serif: 'Some sans serif font', sans-serif;
// @serif: 'Some serif font', serif;
@color-blue-light:  #6464a3;
@color-blue:        #444488;
@color-blue-dark:   #07074e;

@color-green-light: #bbee66;
@color-green:       #a3d93d;

@color-white:       #ffffff;
@color-gray-light:  #f5f4f4;
@color-gray:        #c5c5c5;
@color-gray-dark:   #7d7d7d;
@color-red:         #ff2d56;
@color-orange:      #fa8d00;
@color-yellow:      #f5d000;

@font-sans-serif: 'Lato', Helvetica, sans-serif;

@header-height: 50px;

@tooltip-max-width: 250px;
@tooltip-min-width: 185px;

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0.8) rotate(-180deg);
  }
  100% {
    opacity: 0.5;
    transform: scale(1) rotate(0deg);
  }
}
