#load-control-config-form {

  margin: 1em auto;

  & > .wrapper {
    position: relative;
    &.busy {
      opacity: 0.4;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  }

  .configuration {
    position: relative;
    &.disabled {
      opacity: 0.4;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
  .relay-option {
    &.main {
      font-weight: bold;
    }
  }

  .status {
    text-align: center;
  }
}
